<template>
  <div>
    ORGANIZATION DIRECTORY
    <NewMembership />
    <EditMembership />
  </div>
</template>
<script>
import NewMembership from './NewMembership.vue'
import EditMembership from './EditMembership.vue'

export default {
  name: 'OrganizationDirectory',
  components: { NewMembership, EditMembership },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {},
}
</script>
<style lang="scss">
.inner-tab-title-class {
  .nav-link {
    color: #6e6b7b !important;
  }
  .nav-link.active {
    font-weight: 500;
  }
  .nav-link:after {
    background: linear-gradient(30deg, #6e6b7b, #6e6b7b) !important;
  }
}
</style>
