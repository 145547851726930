<template>
  <div>
    <page-loader v-if="loading" type="list" :fluid="this.$route.meta.containerFluid" />
    <portal to="nav-bar-left-column">
      <page-title title="General Settings" subtitle="Manage the locales, projects..." />
    </portal>

    <b-container :fluid="this.$route.meta.containerFluid">
      <b-row class="match-height">
        <b-col lg="5" md="12">
          <Project v-model="project" />
        </b-col>
        <b-col lg="7" sm="12">
          <Endpoints :project="project" />
        </b-col>
        <b-col lg="12" md="12">
          <Localization />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { projects } from '@/api'
import Localization from './components/localization.vue'
import Project from './components/project.vue'
import Endpoints from './components/endpoints.vue'

export default {
  name: 'GeneralSettings',
  components: { Localization, Project, Endpoints },
  data() {
    return {
      loading: false,
      project: {},
    }
  },
  async created() {
    await this.fetchData()
  },
  computed: {},
  methods: {
    async fetchData() {
      let project = await projects.getProject(this.projectName)
      this.project = project.data
    },
  },
}
</script>
