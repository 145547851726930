<template>
  <div>
    <page-loader v-if="loading" type="list" :fluid="this.$route.meta.containerFluid" />
    <b-container :fluid="this.$route.meta.containerFluid">
      <b-card no-body>
        <portal to="nav-bar-right-column">
          <div class="d-flex">
            <InviteMembers :roles="roles" @invited="fetchData" />
          </div>
        </portal>
        <div v-if="!loading">
          <b-table
            striped
            hover
            responsive
            class="position-relative mb-0"
            :items="memberships"
            :fields="columns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :sticky-header="false"
          >
            <template #cell(createdAt)="data">
              <span v-html="data.value"></span>
            </template>
            <template #cell(modifiedAt)="data">
              <span v-html="data.value"></span>
            </template>
            <template #cell(lastName)="data">
              <b-link @click="onEditMembership(data.item)">{{ data.value }}</b-link>
            </template>
            <template #cell(isActive)="data">
              <b-badge :variant="'light-' + (data.item.status == 'active' ? 'success' : 'dark')">{{ data.item.status }}</b-badge>
            </template>
            <template #cell($actions)="data">
              <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditMembership(data.item)">
                <feather-icon icon="Edit2Icon" v-if="$acl.can('membership.accounts.manage')" />
                <feather-icon icon="EyeIcon" v-else />
              </b-button>
              <b-dropdown variant="link" no-caret toggle-class="p-0" right :dropup="true" class="ml-1" v-if="$acl.can('membership.accounts.manage')">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
                </template>
                <b-dropdown-item @click="onDisconnectConfirm(data.item.id)" v-if="$acl.can('membership.accounts.manage')">
                  <feather-icon icon="UserXIcon" class="text-danger darken-2" />
                  <span class="align-middle ml-50 text-danger text-darken-2">Disconnect</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </b-card>
    </b-container>
    <EditMemberhip v-model="selectedMembership" :roles="roles" @modified="onModifiedMembership" />
    <b-modal id="disconnectConfirmModal" hide-footer title="User" size="md" centered hide-header v-model="confirmDisconnectAccount">
      <div class="d-block text-left mt-2">
        <h4>Disconnect Membership</h4>
        <p class="pt-2 h6 font-weight-normal">
          Disconnecting the membership will disconnect the account from this project. The account will not be deleted.
        </p>
        <p class="pt-2 h6 font-weight-normal">
          If you want the account to join the project in the future you can invite it again.
        </p>
        <p class="pt-1 h6 font-weight-normal">Do you want to disconnect the membership?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDisconnectAccount = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDisconnectMembership">Disconnect</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import InviteMembers from './InviteMembers.vue'
import EditMemberhip from './EditMembership.vue'
import DefaultNotifications from '@/components/Notification/default'
import { accounts, memberships } from '@/api/index'
import SorteableComponent from '@/mixins/SorteableComponent'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'AccountsDirectory',
  mixins: [SorteableComponent, FormComponent],
  components: { InviteMembers, EditMemberhip },
  data() {
    return {
      roles: [],
      memberships: [],
      selectedAccountId: '',
      selectedMembership: null,
      confirmDisconnectAccount: false,
      loading: false,
      columns: [
        {
          key: 'lastName',
          label: 'Name',
          sortable: true,
          formatter: (value, key, item) => {
            return `${item.firstName} ${item.lastName}`
          },
        },
        {
          key: 'username',
          label: 'Username',
          value: 'username',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'Active',
          value: 'isActive',
          sortable: true,
        },
        {
          key: 'role',
          label: 'Role',
          value: 'role',
          sortable: false,
          formatter: (value, key, item) => {
            let self = this

            let name = self.roles.find(r => r.id == value)

            if (name) return name.name
            else return value
          },
        },
        {
          key: 'createdAt',
          label: 'Created At',
          value: 'createAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: 'modifiedAt',
          label: 'Modified At',
          value: 'modifiedAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell-medium',
        },
      ],
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData(to.query)
    },
  },
  computed: {},
  mounted() {},
  created() {
    let self = this
    self.loading = true
    accounts.getRoles(self.projectName).then(res => {
      self.roles = res.data
      self.fetchData()
    })
  },
  methods: {
    async fetchData() {
      const self = this
      await memberships.getMemberships(self.projectName).then(res => {
        self.memberships = res.data
        self.loading = false
      })
    },
    onModifiedMembership() {
      this.selectedMembership = null
      this.fetchData()
    },
    onEditMembership(membership) {
      this.selectedMembership = membership
    },
    onDisconnectConfirm(id) {
      this.selectedAccountId = id
      this.confirmDisconnectAccount = true
    },
    onDisconnectMembership() {
      const self = this
      memberships
        .disconnectMembership(self.projectName, self.selectedAccountId)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDisconnectAccount = false
          self.fetchData()
        })
        .catch(err => {
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
  },
}
</script>
<style lang="scss">
.inner-tab-title-class {
  .nav-link {
    color: #6e6b7b !important;
  }
  .nav-link.active {
    font-weight: 500;
  }
  .nav-link:after {
    background: linear-gradient(30deg, #6e6b7b, #6e6b7b) !important;
  }
}
</style>
