<template>
  <b-card class="card card-project">
    <b-card-header class="p-0">
      <b-card-title class="ml-0 font-weight-normal">
        <h5 class="m-0">
          {{ metric.displayName }}
        </h5>
      </b-card-title>
      <b-card-text class="p-0 ">
        <feather-icon icon="HelpCircleIcon" v-b-tooltip.hover.v-primary.top v-b-tooltip.hover.html="`${metric.description}`" size="21" class="text-muted" />
      </b-card-text>
    </b-card-header>
    <b-card-body class="pl-0 pr-0 py-0 pt-0">
      <p class="h4 font-weight-normal mb-2">{{ metricDisplayValue }} / {{ featureDisplayIncludedQuantity }}</p>
      <b-progress :max="featureIncludedQuantity" height="1.5rem" variant="warning">
        <b-progress-bar :value="metric.value"> </b-progress-bar>
      </b-progress>
    </b-card-body>
  </b-card>
</template>
<script>
import { projects } from '@/api'

export default {
  name: 'ProjectMetricCard',
  props: ['metric', 'feature'],
  components: {},
  data() {
    return {}
  },
  async created() {},
  computed: {
    metricDisplayValue() {
      if (this.feature.unitType == 'units') {
        return `${this.metric.value.toLocaleString(this.userLocale)}`
      } else if (this.feature.unitType == 'megabytes' || this.feature.unitType == 'gigabytes' || this.feature.unitType == 'terabytes') {
        return this.$options.filters.formatBytes(this.metric.value, 2, this.userLocale)
      }

      return this.metric.value
    },
    featureDisplayIncludedQuantity() {
      if (this.feature.unitType == 'units') {
        return `${this.feature.includedQuantity.toLocaleString(this.userLocale)}`
      } else if (this.feature.unitType == 'megabytes') {
        return `${this.feature.includedQuantity} MB`
      } else if (this.feature.unitType == 'gigabytes') {
        return `${this.feature.includedQuantity} GB`
      } else if (this.feature.unitType == 'terabyte') {
        return `${this.feature.includedQuantity} TB`
      }

      return this.feature.includedQuantity.toLocaleString(this.userLocale)
    },
    featureIncludedQuantity() {
      if (this.feature.unitType == 'units') {
        return this.feature.includedQuantity
      } else if (this.feature.unitType == 'megabytes') {
        return this.feature.includedQuantity * Math.pow(1024, 2)
      } else if (this.feature.unitType == 'gigabytes') {
        return this.feature.includedQuantity * Math.pow(1024, 3)
      } else if (this.feature.unitType == 'terabyte') {
        return this.feature.includedQuantity * Math.pow(1024, 4)
      }

      return this.feature.includedQuantity
    },
  },
  methods: {},
}
</script>
