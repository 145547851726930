<template>
  <div>
    <page-loader v-if="loading" type="list" :fluid="this.$route.meta.containerFluid" />
    <portal to="nav-bar-left-column">
      <page-title title="Billing" subtitle="Manage billing information and manage subscription plan" />
    </portal>
    <b-container :fluid="this.$route.meta.containerFluid">
      <b-row>
        <b-col md="12">
          <Plan :cbInstance="cbInstance" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <Metrics />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { projects } from '@/api'
import Plan from './components/Plan.vue'
import Metrics from './components/Metrics.vue'
export default {
  name: 'BillingInformation',
  components: {
    Plan,
    Metrics,
  },
  data() {
    return {
      loading: false,
      cbInstance: null,
      cart: null,
      product: null
    }
  },
  async created() {},
  mounted() {
      // setTimeout(() => {
      //   document.getElementById("subscribe").addEventListener("click", function(){
      //   cart.proceedToCheckout();
      // });
      // }, 2000);
    // }
    // el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js')
    // document.body.appendChild(el)
  },
  destroyed() {

  },
  computed: {},
  methods: {},
}
</script>
