<template>
  <div>
    <b-button @click="inviteMembersModal = true" variant="primary" v-if="$acl.can('membership.accounts.invite')"
      ><feather-icon icon="PlusIcon" class="mr-25" /> Invite members
    </b-button>
    <b-modal
      v-model="inviteMembersModal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Send invitation"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            Invite users to join the Team
          </h4>
          <b-card-text>
            Type the email(s) of users and select the role they will be assigned to send the invitation.
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <form-alert :errors="serverErrors" />
        <validation-observer ref="invitationForm">
          <b-form v-on:keyup.enter="onSave" @submit.prevent>
            <b-row v-for="(item, index) in invitation.teamMembers" :key="`tm-invitation-${index}`" class="pb-1">
              <b-col cols="6" class="pr-0 d-flex align-items-start">
                <text-input
                  v-model="item.email"
                  placeholder="name@example.com"
                  name="Email"
                  class="flex-grow-1"
                  css="m-0 p-0"
                  validation-rules="required|email"
                  :vid="`_e${index}`"
                />
              </b-col>
              <b-col cols="6" class="pr-0 mr-0 d-flex align-items-center">
                <validation-provider rules="required" name="Role" v-slot="{ errors }" tag="div" :vid="`_r${index}`" mode="aggressive" class="flex-grow-1">
                  <v-select id="role" v-model="item.role" :options="roles" :reduce="role => role.id" label="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div><feather-icon icon="TrashIcon" size="16" class="cursor-pointer ml-50 text-danger" @click="onRemoveItem(index)" /></div>
              </b-col>
              <!-- <b-col cols="1" class="pl-0 d-flex align-items-center">
                <feather-icon icon="TrashIcon" size="16" class="cursor-pointer ml-50 text-danger" @click="onRemoveItem(index)" v-if="index > 0" />
              </b-col> -->
            </b-row>
            <b-button variant="primary" @click="onAddItem"><feather-icon icon="PlusIcon" class="mr-25" /> Add</b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { memberships } from '@/api/index'
import vSelect from 'vue-select'
import DefaultNotifications from '@/components/Notification/default'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProjectInviteMembers',
  mixins: [FormComponent],
  props: ['roles'],
  components: { vSelect },
  data() {
    return {
      inviteMembersModal: false,
      invitation: {
        teamMembers: [
          {
            email: '',
            role: '',
          },
        ],
        message: '',
      },
    }
  },
  watch: {
    invitation: {
      immediate: false,
      deep: true,
      handler(newValue) {
        if (newValue && newValue.teamMembers[newValue.teamMembers.length - 1].email) {
          // this.invitation.teamMembers.push({
          //   email: '',
          //   role: '',
          // })
        }
      },
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    onAddItem() {
      this.invitation.teamMembers.push({
        email: '',
        role: '',
      })
    },
    async onSave(e) {
      e.preventDefault()

      const self = this

      const validation = await self.$refs.invitationForm.validateWithInfo()

      if (validation.isValid) {
        memberships
          .sendInvitation(self.projectName, self.invitation)
          .then(res => {
            self.ResetErrors()
            self.$bus.$emit('notification', { ...DefaultNotifications.saveSuccessful, text: 'Invitation(s) sent!' })
            self.inviteMembersModal = false
            self.$emit('invited')
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
    reset() {
      this.inviteMembersModal = false
      this.invitation = {
        teamMembers: [
          {
            email: '',
            role: '',
          },
        ],
      }
    },
  },
}
</script>
