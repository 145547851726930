<template>
  <div>
    <b-card>
      <form-alert :errors="serverErrors" />
      <portal to="nav-bar-right-column">
        <div class="d-flex align-items-center">
          <span v-if="hasErrors" class="mr-1 d-flex align-items-center text-danger">
            <feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes
          </span>
          <b-link size="xs" class="font-weight-normal text-secondary mr-1 font-small-2" @click="$emit('cancel')">
            <FeatherIcon icon="CornerDownLeftIcon" class="mr-25" size="12" />
            Back to list</b-link
          >
          <b-button type="submit" variant="primary" class="mr-1" @click="onSave" v-if="$acl.can('admin.webhooks.create')"> Save </b-button>
        </div>
      </portal>
      <validation-observer ref="webhookForm">
        <fieldset>
          <b-form @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="webhook.name" placeholder="Name" label="Name" validation-rules="required" />
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="enabled" name="enabled" v-model="webhook.enabled">
                      Enabled
                    </b-form-checkbox>
                    <b-form-checkbox id="include-payload" name="include-payload" v-model="webhook.includePayload">
                      Include Payload
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <h3>Conditions</h3>
                <hr />
              </b-col>
              <b-col cols="12">
                <triggers v-model="webhook" :models="models" :disabled="false" />
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col cols="12">
                <b-card no-body class="mt-1"> </b-card>
              </b-col>
            </b-row> -->
            <b-row class="mt-2">
              <b-col cols="12">
                <h3>HTTP Settings</h3>
                <hr />
              </b-col>
              <b-col cols="6">
                <text-input v-model="webhook.url" placeholder="Url" label="Url" validation-rules="required" />
              </b-col>
              <b-col cols="12">
                <headers v-model="webhook.headers" />
              </b-col>
            </b-row>
          </b-form>
        </fieldset>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import { webhooks } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'
import Headers from './Headers.vue'
import Triggers from './Triggers.vue'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProjectNewWebhook',
  mixins: [FormComponent],
  components: { Headers, Triggers },
  props: ['models'],
  data() {
    return {
      isDirty: false,
      webhook: { enabled: true, includePayload: true, headers: [], events: [], entityTypeIds: [] },
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    async onSave() {
      const self = this

      const validation = await self.$refs.webhookForm.validateWithInfo()

      if (validation.isValid) {
        webhooks
          .createWebhook(self.projectName, self.webhook)
          .then(res => {
            self.ResetErrors()
            self.$emit('created')
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
  },
}
</script>
