<template>
  <div>
    <page-loader v-if="loading" type="list" :fluid="this.$route.meta.containerFluid" />
    <portal to="nav-bar-left-column">
      <page-title title="Webhooks" subtitle="Notifications to external API of event generated by the application" />
    </portal>
    <portal to="nav-bar-right-column" v-if="$acl.can('admin.webhooks.create')">
      <b-button @click="newWebhook = true" variant="primary"><feather-icon icon="PlusIcon" class="mr-25" /> Create webhook</b-button>
    </portal>
    <b-container :fluid="this.$route.meta.containerFluid">
      <b-card no-body v-if="!selectedWebhook && !newWebhook">
        <portal to="nav-bar-right-column"> </portal>
        <div v-if="!loading">
          <b-table
            striped
            hover
            responsive
            class="position-relative mb-0"
            :items="webhooks"
            :fields="columns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :sticky-header="false"
          >
            <!-- <template #head($selection)="data">
                <b-form-checkbox @change="onSelectAllAccounts" />
              </template> -->
            <!-- <template #cell($selection)="data">
                <b-form-checkbox @change="onSelectAccount($event, data.item.id)" :checked="isAccountSelected(data.item.id)" />
              </template> -->
            <template #cell(createdAt)="data">
              <span v-html="data.value"></span>
            </template>
            <template #cell(modifiedAt)="data">
              <span v-html="data.value"></span>
            </template>
            <template #cell(lastName)="data">
              <b-link @click="onEditWebhook(data.item)">{{ data.value }}</b-link>
            </template>
            <template #cell(enabled)="data">
              <b-badge :variant="'light-' + (data.item.enabled ? 'success' : 'warning')">{{ data.item.enabled ? 'active' : 'inactive' }}</b-badge>
            </template>
            <template #cell($actions)="data">
              <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditWebhook(data.item)">
                <feather-icon icon="Edit2Icon" v-if="$acl.can('admin.webhooks.update')" />
                <feather-icon icon="EyeIcon" v-else />
              </b-button>
              <b-dropdown variant="link" no-caret toggle-class="p-0" right :dropup="true" class="ml-1" v-if="$acl.can('admin.webhooks.delete')">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
                </template>

                <b-dropdown-item @click="onDeleteConfirm(data.item.id)" v-if="$acl.can('admin.webhooks.delete')">
                  <feather-icon icon="TrashIcon" class="text-danger darken-2" />
                  <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </b-card>
      <new-webhook v-if="newWebhook" @cancel="newWebhook = false" :models="models" @created="onCreated" />
      <edit-webhook v-if="selectedWebhook" v-model="selectedWebhook" :models="models" @cancel="refreshView" @delete="refreshView" />
    </b-container>
    <b-modal id="deleteConfirmModal" hide-footer title="User" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete Webhook</h4>
        <p class="h6 font-weight-normal">Do you want to delete the webhook?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDeleteWebhook">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { contentTypes, webhooks } from '@/api'
import NewWebhook from './components/NewWebhook.vue'
import EditWebhook from './components/EditWebhook.vue'
import DefaultNotifications from '@/components/Notification/default'
import SorteableComponent from '@/mixins/SorteableComponent'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProjectWebHooks',
  mixins: [FormComponent, SorteableComponent],
  components: {
    NewWebhook,
    EditWebhook,
  },
  data() {
    return {
      loading: false,
      webhooks: [],
      selectedWebhookId: 0,
      columns: [
        // {
        //   key: '$selection',
        //   label: '',
        //   sortable: false,
        //   tdClass: 'icon-cell',
        // },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'url',
          label: 'Url',
          value: 'url',
        },
        {
          key: 'enabled',
          label: 'Enabled',
          value: 'enabled',
          sortable: true,
        },
        {
          key: 'entityType',
          label: 'Entity Type',
          value: 'entityType',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created At',
          value: 'createAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: 'modifiedAt',
          label: 'Modified At',
          value: 'modifiedAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell-medium',
        },
      ],
      confirmDelete: false,
      newWebhook: false,
      selectedWebhook: null,
      models: [],
    }
  },
  async created() {
    await this.fetchData()
    await this.fetchLookups()
  },
  methods: {
    async fetchLookups() {
      this.models = this.$ContentTypes.getModels()
    },
    async fetchData() {
      const self = this
      self.loading = true
      await webhooks.getWebhooks(self.projectName).then(res => {
        self.webhooks = res.data
        self.loading = false
      })
    },
    onEditWebhook(webhook) {
      this.selectedWebhook = webhook
    },
    async onCreated() {
      this.newWebhook = false
      await this.fetchData()
    },
    refreshView() {
      this.newWebhook = false
      this.selectedWebhook = null
      this.fetchData()
    },
    onDeleteConfirm(id) {
      this.selectedWebhookId = id
      this.confirmDelete = true
    },
    async onDeleteWebhook() {
      const self = this
      webhooks
        .deleteWebhook(self.projectName, self.selectedWebhookId)
        .then(res => {
          self.ResetErrors()
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDelete = false
          self.fetchData()
        })
        .catch(err => {
          self.confirmDelete = false
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
  },
}
</script>
