<template>
  <div>
    <div v-if="$acl.can('admin.tokens.manage')">
      <page-loader v-if="loading" type="list" :fluid="this.$route.meta.containerFluid" />
      <portal to="nav-bar-left-column">
        <page-title
          title="Api Keys"
          subtitle="Api Keys for API Authorization. You can use the Api Key as a Bearer token or as a x-api-key in the Request Headers"
        />
      </portal>
      <b-container :fluid="this.$route.meta.containerFluid">
        <b-card no-body v-if="!selectedApiToken">
          <portal to="nav-bar-right-column">
            <new-api-token @created="onCreatedApiToken" />
          </portal>
          <div v-if="!loading">
            <b-table
              striped
              hover
              responsive
              class="position-relative mb-0"
              :items="apitokens"
              :fields="columns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :sticky-header="false"
            >
              <template #cell(token)="data">
                <div class="d-flex align-items-center">
                  <div class="">
                    <span v-for="index in 40" :key="index">&#x25CF;</span>
                  </div>
                  <b-link
                    v-b-tooltip.hover.v-secondary.top
                    variant="link"
                    class="ml-1"
                    title="Copy to clipboard"
                    v-clipboard:copy="data.item.token"
                    v-clipboard:success="onCopyKeySuccess"
                  >
                    <feather-icon class="cursor-pointer text-dark" icon="CopyIcon" />
                  </b-link>
                </div>
              </template>
              <template #cell(name)="data">
                <b-link @click="onEditApiToken(data.item)">{{ data.value }}</b-link>
              </template>
              <template #cell(validFrom)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(validTo)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(isActive)="data">
                <b-badge :variant="'light-' + (data.item.isActive ? 'success' : 'warning')">{{ data.item.isActive ? 'active' : 'not active' }}</b-badge>
              </template>
              <template #cell($actions)="data">
                <!-- <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditRole(data.item.id)">
                  <feather-icon icon="Edit2Icon" />
                </b-button> -->
                <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditApiToken(data.item)">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </template>
            </b-table>
          </div>
        </b-card>
        <edit-api-token v-model="selectedApiToken" @cancel="refreshView" @delete="refreshView" v-else />
      </b-container>
    </div>
    <div v-else>
      <forbidden />
    </div>
  </div>
</template>
<script>
import NewApiToken from './components/NewApiToken.vue'
import EditApiToken from './components/EditApiToken.vue'
import { apitokens } from '@/api/index'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DefaultNotifications from '@/components/Notification/default'
import SorteableComponent from '@/mixins/SorteableComponent'

export default {
  name: 'ApiTokens',
  components: { NewApiToken, EditApiToken },
  mixins: [SorteableComponent, togglePasswordVisibility],
  data() {
    return {
      apitokens: [],
      confirmDelete: false,
      selectedApiToken: null,
      loading: false,
      columns: [
        {
          key: 'name',
          label: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          key: 'token',
          label: 'Token',
          value: 'token',
          sortable: false,
        },
        {
          key: 'isActive',
          label: 'Active',
          value: 'isActive',
          sortable: true,
        },
        {
          key: 'validFrom',
          label: 'Valid From',
          value: 'validFrom',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: 'validTo',
          label: 'Valid To',
          value: 'validTo',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell',
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    let self = this

    self.fetchData()
  },
  methods: {
    async fetchData(item) {
      const self = this
      self.loading = true
      await apitokens.getApiTokens(self.projectName).then(res => {
        self.apitokens = res.data
        self.loading = false
        if (item) {
          let token = self.$_.filter(self.apitokens, function(a) {
            return a.id == item.id
          })

          if (token && token.length) {
            self.selectedApiToken = token[0]
          }
        }
      })
    },
    onEditApiToken(apitoken) {
      this.selectedApiToken = apitoken
    },
    async onCreatedApiToken(apitoken) {
      this.fetchData(apitoken)
    },
    refreshView() {
      this.selectedApiToken = null
      this.fetchData()
    },
    onCopyKeySuccess() {
      this.$bus.$emit('notification', { ...DefaultNotifications.infoSuccesful, text: 'Api Token copied to clipboard!', position: 'top-right', icon: 'KeyIcon' })
    },
  },
}
</script>
