<template>
  <div>
    <div class="mb-1">
      <h6 class="mt-1">Headers</h6>
      <b-row v-for="(header, index) in internal" :key="`header-${index}`">
        <b-col cols="3" class="pr-0 mr-0">
          <text-input v-model="header.key" placeholder="Key" validation-rules="simple_required" />
        </b-col>
        <b-col cols="4" class="pr-0 mr-0">
          <text-input v-model="header.value" placeholder="Value" validation-rules="simple_required" />
        </b-col>
        <b-col cols="1" class="pl-0">
          <b-button variant="link" @click="onRemoveItem(index)" class="pl-25 text-danger">remove</b-button>
        </b-col>
      </b-row>
    </div>
    <b-button variant="primary" @click="onAddHeader"><feather-icon icon="PlusIcon" class="mr-25" /> Add Header</b-button>
  </div>
</template>
<script>
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('simple_required', {
  ...required,
  message: 'Required',
})

export default {
  name: 'NewWebhook',
  props: ['value'],
  components: {},
  data() {
    return {
      internal: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.internal = newValue
      },
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    onAddHeader() {
      this.internal.push({ key: '', value: '' })
      this.$emit('input', [...this.internal])
    },
    onRemoveItem(index) {
      this.internal.splice(index, 1)
      this.$emit('input', [...this.internal])
    },
  },
}
</script>
