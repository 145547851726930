<template>
  <b-row v-if="data">
    <b-col md="3">
      <MetricCard :metric="data.metrics.total_entities" :feature="getFeature('entities')" />
    </b-col>
    <b-col md="3">
      <MetricCard :metric="data.metrics.storage_size" :feature="getFeature('storage_size')" />
    </b-col>
    <b-col md="3">
      <MetricCard :metric="data.metrics.users" :feature="getFeature('users')" />
    </b-col>
  </b-row>
</template>
<script>
import { projects } from '@/api'
import MetricCard from './MetricCard.vue'
export default {
  name: 'BillingInformation',
  components: {
    MetricCard,
  },
  data() {
    return {
      loading: false,
      data: null,
    }
  },
  async created() {
    await this.fetchData()
  },
  computed: {},
  methods: {
    async fetchData() {
      const self = this
      projects.getMetrics(self.projectName).then(res => {
        self.data = res.data
      })
    },
    getFeature(id) {
      const self = this
      const feature = self.Project.plan.features.find(f => f.id === id)

      if (feature) return feature
      else
        return {
          id: id,
        }
    },
  },
}
</script>
