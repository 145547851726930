<template>
  <div>
    <div v-if="$acl.can('membership.accounts.read')">
      <portal to="nav-bar-left-column">
        <page-title title="Memberships" subtitle="Accounts that can work on this project" />
      </portal>
      <AccountsDirectory v-if="mode == 'accounts-directory'" />
      <OrganizationDirectory v-if="mode == 'organization-directory'" />
    </div>
    <div v-else>
      <forbidden />
    </div>
  </div>
</template>
<script>
import AccountsDirectory from './AccountsDirectory/Index.vue'
import OrganizationDirectory from './OrganizationDirectory/Index.vue'
export default {
  name: 'ProjectMemberships',
  components: { AccountsDirectory, OrganizationDirectory },
  data() {
    return {}
  },
  watch: {},
  computed: {
    mode() {
      return 'accounts-directory'
    },
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>
<style lang="scss">
.inner-tab-title-class {
  .nav-link {
    color: #6e6b7b !important;
  }
  .nav-link.active {
    font-weight: 500;
  }
  .nav-link:after {
    background: linear-gradient(30deg, #6e6b7b, #6e6b7b) !important;
  }
}
</style>
