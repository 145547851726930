<template>
  <div>
    <b-card>
      <form-alert :errors="serverErrors" />
      <portal to="nav-bar-right-column">
        <div class="d-flex align-items-center">
          <span v-if="hasErrors" class="mr-1 d-flex align-items-center text-danger">
            <feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes
          </span>
          <b-link size="xs" class="font-weight-normal text-secondary mr-1 font-small-2" @click="$emit('cancel')">
            <FeatherIcon icon="CornerDownLeftIcon" class="mr-25" size="12" />
            Back to list</b-link
          >
          <b-button type="submit" :variant="saveButtonVariant" class="mr-1" @click="onSave" :disabled="!(isDirty && $acl.can('membership.roles.update'))">
            Save
          </b-button>
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="confirmDelete = true" v-if="$acl.can('membership.roles.delete')">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </portal>
      <validation-observer ref="roleForm">
        <fieldset :disabled="!$acl.can('membership.roles.update')">
          <b-form @submit.prevent v-on:keyup.enter="onFormEnterKey">
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="role.name" placeholder="Name" label="Name" validation-rules="required" :readonly="role.system" />
              </b-col>
            </b-row>
          </b-form>
          <b-row>
            <b-col cols="12">
              <!-- <vue-json-pretty :data="role" /> -->
              <PermissionSelector v-model="role.permissions" :permissions="permissions" />
            </b-col>
          </b-row>
        </fieldset>
      </validation-observer>
    </b-card>
    <b-modal id="deleteConfirmModal" hide-footer title="Role" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete Role</h4>
        <p class="h6 font-weight-normal">Do you want to delete the role?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { accounts } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import PermissionSelector from '@/components/Permission/PermissionSelector.vue'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProjectEditRole',
  mixins: [FormComponent],
  props: ['value'],
  components: { PermissionSelector },
  data() {
    return {
      permissions: [],
      confirmDelete: false,
      isDirty: false,
      role: { permissions: [] },
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.original = this.value
        this.role = this.$_.cloneDeep(this.original)
      },
    },
    role: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  mounted() {},
  created() {
    this.fetchPermissions()
  },
  methods: {
    async fetchPermissions() {
      const self = this
      await accounts.getPermissionSet(self.projectName).then(res => {
        self.permissions = res.data
      })
    },
    async onFormEnterKey() {
      const self = this
      if (self.isDirty > 0 && self.$acl.can('membership.roles.update')) {
        await self.onSave()
      }
    },
    async onSave() {
      const self = this

      const validation = await self.$refs.roleForm.validateWithInfo()

      if (validation.isValid) {
        accounts
          .updateRole(self.projectName, self.role.id, self.role)
          .then(res => {
            self.ResetErrors()
            self.$emit('input', { ...self.role })
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
    async onDelete() {
      const self = this
      accounts
        .deleteRole(self.projectName, self.role.id)
        .then(res => {
          self.ResetErrors()
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.$emit('delete')
        })
        .catch(err => {
          self.confirmDelete = false
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
  },
}
</script>
