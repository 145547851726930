<template>
  <div>
    <b-card class="card card-project" style="min-height:260px">
      <b-card-header class="pl-2 pb-0 pt-0">
        <b-card-title class="">
          <h1>Endpoints</h1>
        </b-card-title>
        <b-card-text class="font-small-1 text-white mr-25 mb-0">
          <b-avatar class="bg-primary mr-25" size="32">
            <feather-icon size="18" icon="WifiIcon" />
          </b-avatar>
        </b-card-text>
      </b-card-header>
      <b-card-body class="py-0 pt-50">
        <b-row>
          <b-col lg="6" md="12" class="">
            <b-form-group label="Project Name" class="mr-1 w-100" label-class="text-dark">
              <b-input-group class="input-group-merge">
                <b-form-input :value="project.name" type="text" class="form-control-merge" :readonly="true" />
                <b-input-group-append is-text>
                  <div>
                    <b-link v-b-tooltip.hover.v-secondary.top variant="link" title="Copy to clipboard" v-clipboard:copy="project.name">
                      <feather-icon class="cursor-pointer text-dark" icon="CopyIcon" />
                    </b-link>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" class=""> </b-col>
          <b-col lg="6" md="12" class="">
            <b-form-group label="GraphQL" class="mr-1 w-100" label-class="text-dark">
              <b-input-group class="input-group-merge">
                <b-form-input :value="graphQlEndpoint" type="text" class="form-control-merge" :readonly="true" />
                <b-input-group-append is-text>
                  <div>
                    <b-link v-b-tooltip.hover.v-secondary.top variant="link" title="Copy to clipboard" v-clipboard:copy="graphQlEndpoint">
                      <feather-icon class="cursor-pointer text-dark" icon="CopyIcon" />
                    </b-link>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" class="">
            <b-form-group label="REST" class="mr-1 w-100" label-class="text-dark">
              <b-input-group class="input-group-merge">
                <b-form-input :value="restEndpoint" type="text" class="form-control-merge" :readonly="true" />
                <b-input-group-append is-text>
                  <div>
                    <b-link v-b-tooltip.hover.v-secondary.top variant="link" title="Copy to clipboard" v-clipboard:copy="restEndpoint">
                      <feather-icon class="cursor-pointer text-dark" icon="CopyIcon" />
                    </b-link>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'GeneralSettingsEndpoints',
  components: {},
  props: ['project'],
  data() {
    return {}
  },
  created() {},
  computed: {
    restEndpoint() {
      return `${window.location.origin}/api/${this.project.name}`
    },
    graphQlEndpoint() {
      return `${window.location.origin}/api/${this.project.name}/graphql`
    },
  },
  methods: {},
}
</script>

<!-- <style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.card-project {
  background-color: $primary;
}
.img-left {
  width: 150px;
}
</style> -->
