<template>
  <div>
    <div>
      <b-row>
        <b-col cols="3">
          <validation-provider rules="required" name="Entity Type" v-slot="{ errors }" tag="div">
            <b-form-group label="Entity Type" label-for="entity-type" :class="errors.length > 0 ? 'is-invalid' : null">
              <v-select
                id="entity-type"
                v-model="internal.entityType"
                :options="entityTypes"
                :reduce="et => et.key"
                label="name"
                :no-drop="disabled"
                :clearable="!disabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-if="internal.entityType">
        <b-col cols="3">
          <validation-provider rules="required" name="Events" v-slot="{ errors }" tag="div">
            <b-form-group label="Events" label-for="events" :class="errors.length > 0 ? 'is-invalid' : null">
              <v-select
                id="events"
                multiple
                v-model="internal.events"
                :options="getEventsOptions(internal.entityType)"
                :no-drop="disabled"
                :clearable="!disabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-if="internal.entityType == 'content'">
        <b-col cols="5">
          <validation-provider rules="required" name="Type of Contents" v-slot="{ errors }" tag="div">
            <b-form-group label="Type of Contents" label-for="type-of-contents">
              <v-select
                id="type-of-content"
                multiple
                v-model="internal.entityTypeIds"
                :options="models"
                :reduce="m => m.id"
                label="name"
                :no-drop="disabled"
                :clearable="!disabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('simple_required', {
  ...required,
  message: 'Required',
})

export default {
  name: 'NewWebhook',
  props: ['value', 'models', 'disabled'],
  components: { vSelect },
  data() {
    return {
      internal: {},
      entityTypes: [
        {
          key: 'content',
          name: 'Contents',
          events: ['create', 'update', 'delete'],
        },
        {
          key: 'media',
          name: 'Media Library',
          events: ['upload', 'update', 'delete'],
        },
        {
          key: 'user',
          name: 'Users',
          events: ['create', 'update', 'delete', 'changepassword'],
        },
      ],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.internal = newValue
      },
    },
    'internal.entityType': {
      handler(newValue) {
        let events = this.getEventsOptions(newValue)
        let newEvents = []

        for (let i = 0; i < this.internal.events.length; i++) {
          if (events.includes(this.internal.events[i])) {
            newEvents.push(this.internal.events[i])
          }
        }

        this.internal.events = newEvents
        this.internal.entityTypeIds = []
      },
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    getEventsOptions(entityType) {
      const et = this.entityTypes.filter(e => e.key == entityType)

      if (et && et.length) {
        return et[0].events
      }

      return []
    },
  },
}
</script>
