<template>
  <div>
    <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-1">
          <feather-icon icon="LockIcon" size="14" />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table-simple striped responsive v-for="(area, index) in permissions" :key="'sp_' + index" class="position-relative mb-0">
        <b-thead>
          <b-th class="action-cell-medium" align="center"> </b-th>
          <b-th style="width:300px">
            <span class="text-uppercase">{{ area.group }}</span>
          </b-th>
          <b-th>
            <span class="text-uppercase">Permissions</span>
          </b-th>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(section, sectionIndex) in area.sections" :key="'sp_' + index + '_' + sectionIndex">
            <b-td class="action-cell-medium text-center">
              <b-button
                size="sm"
                :disabled="isSelectDisabled(area.key, section)"
                variant="link"
                @click="deselectAll(area, section)"
                v-if="isAllSelected(area, section)"
                >Deselect</b-button
              >
              <b-button size="sm" :disabled="isSelectDisabled(area.key, section)" variant="primary" @click="selectAll(area, section)" v-else>Select</b-button>
            </b-td>
            <b-td class="py-1">
              {{ section.name }}
            </b-td>
            <b-td class="py-1">
              <div class="inline-spacing">
                <b-form-checkbox
                  v-for="permission in section.permissions"
                  :key="'sp_' + index + '_' + sectionIndex + '_' + permission"
                  :name="permission"
                  :checked="getPermissionIndex(area.key, section.feature, permission) > -1"
                  @change="onSelectPermission($event, area.key, section.feature, permission)"
                  :disabled="isCheckboxDisabled(area.key, section.feature, permission)"
                >
                  {{ permission }}
                </b-form-checkbox>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'PermissionSelector',
  props: ['value', 'permissions'],
  components: {},
  mixins: [],
  data() {
    return {
      internal: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.internal = this.value
      },
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    getPermissionIndex(area, feature, perm) {
      const permission = `${area}.${feature}.${perm}`

      return this.$_.findIndex(this.internal, p => {
        return p === permission
      })
    },
    onSelectPermission(event, area, feature, perm) {
      const index = this.getPermissionIndex(area, feature, perm)

      if (event && index < 0) {
        this.internal.push(`${area}.${feature}.${perm}`)
      }

      if (event === false && index >= 0) {
        this.internal.splice(index, 1)
      }

      this.fixPermissions()
    },
    isAllSelected(area, section) {
      for (let i = 0, len = section.permissions.length; i < len; i++) {
        const index = this.getPermissionIndex(area.key, section.feature, section.permissions[i])
        if (index < 0) {
          return false
        }
      }
      return true
    },
    selectAll(area, section) {
      this.$_.forEach(section.permissions, p => {
        const index = this.getPermissionIndex(area.key, section.feature, p)
        if (index < 0) {
          this.internal.push(`${area.key}.${section.feature}.${p}`)
        }
      })

      this.fixPermissions()
    },
    deselectAll(area, section) {
      this.$_.forEach(section.permissions, p => {
        const index = this.getPermissionIndex(area.key, section.feature, p)
        if (index >= 0) {
          this.internal.splice(index, 1)
        }
      })

      this.fixPermissions()
    },
    fixPermissions() {
      const self = this
      const fullPerms = []
      const clone = self.$_.cloneDeep(this.internal)

      //Get the full permissions in array
      for (let index = 0; index < this.internal.length; index++) {
        let perm = this.internal[index].split('.')
        if (perm[1] == '*') {
          fullPerms.push(perm)
        }
      }

      //Remove all permissions of area that has full perm
      for (let index = 0; index < fullPerms.length; index++) {
        let fperm = fullPerms[index]

        self.$_.remove(clone, p => {
          let perm = p.split('.')

          return perm[0] == fperm[0] && perm[1] != fperm[1] && perm[2] == fperm[2]
        })
      }

      self.internal = [...clone]

      self.$emit('input', self.internal)
    },

    isCheckboxDisabled(area, feature, permission) {
      if (feature == '*') return false
      return this.internal.includes(`${area}.*.${permission}`)
    },
    isSelectDisabled(area, section) {
      if (section.feature == '*') return false

      for (let index = 0; index < section.permissions.length; index++) {
        let perm = `${area}.*.${section.permissions[index]}`
        if (!this.internal.includes(perm)) {
          return false
        }
      }

      return true
    },
  },
}
</script>
