<template>
  <div>
    NEW MEMBERSHIP
  </div>
</template>
<script>
export default {
  name: 'NewMembership',
  components: {},
  props: [],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {},
}
</script>
