<template>
  <div v-if="membership">
    <b-modal
      v-model="editMembershipModal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="md"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      :ok-disabled="!$acl.can('membership.accounts.manage')"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            Edit Membership
          </h4>
          <b-card-text>
            Edit the membership's information
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <div v-if="membership">
          <form-alert :errors="serverErrors" />
          <fieldset :disabled="!$acl.can('membership.accounts.manage')">
            <validation-observer ref="accountForm">
              <b-form v-on:keyup.enter="onSave" @submit.prevent>
                <b-row align-v="center">
                  <!-- names -->
                  <b-col cols="12">
                    <!-- <b-card no-body class="shadow-md">
                      <b-card-body class="pb-50"> -->
                    <b-row class="mb-1">
                      <b-col lg="3" md="12" class="mb-0 mb-md-0">
                        <b-media no-body>
                          <b-media-body class="mt-75 ml-75">
                            <div class="text-center">
                              <div class="pb-2">
                                <b-avatar size="70" alt="" class="badge-minimal bg-secondary bg-lighten-3">
                                  <FeatherIcon icon="UserIcon" size="32" />
                                </b-avatar>
                              </div>
                            </div>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col lg="9" md="12" class="text-center text-md-left">
                        <ProfileCardInfo :data="membership" />
                      </b-col>
                    </b-row>
                    <!-- </b-card-body>
                    </b-card> -->
                  </b-col>

                  <b-col cols="12">
                    <validation-provider rules="required" name="Role" v-slot="{ errors }" tag="div">
                      <b-form-group label="Role" label-for="role" :class="errors.length > 0 ? 'is-invalid' : null">
                        <v-select
                          id="role"
                          v-model="membership.role"
                          :options="roles"
                          :reduce="role => role.id"
                          label="name"
                          v-if="$acl.can('membership.accounts.manage')"
                        />
                        <div v-else>
                          <b-badge v-for="(item, index) in account.roles" :key="index" pill variant="primary">
                            {{ roleName(item) }}
                          </b-badge>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- status -->
                  <b-col cols="12">
                    <b-form-group>
                      <div class="inline-spacing">
                        <b-form-checkbox id="isActive" name="isActive" :checked="membership.status == 'active' ? true : false" @change="onChangeStatus">
                          Is Active in this Project
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { accounts, memberships } from '@/api/index'
import vSelect from 'vue-select'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'
import ProfileCardInfo from '@/components/Profile/ProfileCardInfo.vue'
import FormComponent from '@/mixins/FormComponent'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  name: 'ProjectEditMembership',
  mixins: [FormComponent],
  components: {
    vSelect,
    ProfileCardInfo,
  },
  props: ['value', 'roles'],
  data() {
    return {
      membership: {},
      original: {},
      editMembershipModal: false,
      serverErrors: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.original = this.value
        this.membership = this.$_.cloneDeep(this.original)
        if (!newValue) {
          this.editMembershipModal = false
        } else {
          this.editMembershipModal = true
        }
      },
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    onSetRole(e) {
      this.membership.role = e
    },
    async onSave(e) {
      e.preventDefault()
      const self = this
      const validation = await self.$refs.accountForm.validateWithInfo()
      if (validation.isValid) {
        let data = {
          accountId: self.membership.id,
          role: self.membership.role,
          isActive: self.membership.status == 'active',
        }
        memberships
          .updateMembership(self.projectName, self.membership.id, data)
          .then(res => {
            self.ResetErrors()
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.editAccountModalVisible = false
            self.$emit('modified')
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
    onChangeStatus(e) {
      if (e) {
        this.membership.status = 'active'
      } else {
        this.membership.status = 'disabled'
      }
    },
    reset() {
      this.editMembershipModal = false
      this.membership = {}
      this.original = {}
      this.$emit('input', null)
    },
    roleName(id) {
      var roles = this.roles.filter(r => r.id == id)

      if (roles && roles.length) {
        return roles[0].name
      }

      return id
    },
  },
}
</script>
