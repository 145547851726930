<template>
  <div>
    <b-card class="card card-project px-0" style="min-height:260px" no-body>
      <b-card-body class="m-0 d-flex flex-column justify-content-center">
        <b-row align-v="center">
          <b-col lg="4" md="12" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-body class="mt-0 ml-0">
                <div class="text-center">
                  <div class="pb-2">
                    <b-avatar size="150" alt="" class="badge-minimal bg-primary bg-lighten-3">
                      <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" class="img-left d-none d-md-block" />
                    </b-avatar>
                  </div>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col lg="8" md="12" class="text-center text-md-left">
            <div class="mb-1 text-white">
              <p class="font-small-2 m-0">Name</p>
              <h3 class="text-white">{{ value.displayName }}</h3>
              <!-- <p class="font-small-2 m-0">Api</p>
              <b-badge>{{ data.name }}</b-badge> -->
              <div v-if="value.description">
                <p class="font-small-2 m-0 mt-1 ">Description</p>
                <p class="font-size-4 font-weight-normal pb-1 text-white">{{ value.description ? value.description : '-- no description --' }}</p>
              </div>
            </div>
            <b-button variant="outline-secondary bg-white" @click="editProject = true" v-if="$acl.can('admin.project.manage')">Edit</b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="editProject"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="HomeIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            Project
          </h4>
          <b-card-text>
            Edit project details
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <page-loader v-if="loading" type="content" :fluid="true" size="small" />
        <div v-if="!loading">
          <form-alert :errors="serverErrors" />
          <fieldset :disabled="false">
            <validation-observer ref="projectForm">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12">
                    <text-input
                      v-model="data.displayName"
                      size="lg"
                      placeholder="ex: Catalog App, Web Site.... "
                      label="Display Name"
                      label-class="font-weight-bold"
                      validation-rules="required"
                    />
                  </b-col>
                  <b-col cols="12">
                    <text-input
                      v-model="data.name"
                      size="lg"
                      placeholder="Project name"
                      label="Name"
                      label-class="font-weight-bold"
                      hit="The name used for the Api Url. If you want to change it, keep in consideration you have to update the Endpoints on the consumers side"
                      validation-rules="required|project-api-key"
                    />
                  </b-col>
                  <b-col cols="12">
                    <text-area v-model="data.description" placeholder="Description" label="Description" />
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { projects } from '@/api'
import * as changeCase from 'change-case'
import * as validations from '@/libs/validations/validations.js'
import DefaultNotifications from '@/components/Notification/default'
import { Url } from 'url'
import { mapActions, mapGetters } from 'vuex'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProjectGeneralSettingsProject',
  mixins: [FormComponent],
  components: {},
  props: ['value'],
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.data = this.$_.cloneDeep(newVal)
      },
    },
  },
  data() {
    return {
      isDirty: false,
      loading: false,
      editProject: false,
      data: {},
    }
  },
  created() {},
  computed: {},
  methods: {
    async onSave(e) {
      e.preventDefault()
      const self = this

      const validation = await self.$refs.projectForm.validateWithInfo()

      if (validation.isValid) {
        projects
          .updateProject(self.projectName, self.data)
          .then(res => {
            self.ResetErrors()
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.editProject = false
            self.InvalidateProjectContext(self.projectName)
            self.$emit('input', { ...self.data })
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
    reset() {
      this.editProject = false
      this.data = this.$_.cloneDeep(this.value)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.card-project {
  background-color: $primary;
}
.img-left {
  width: 150px;
}
</style>
