<template>
  <div>
    <b-card>
      <form-alert :errors="serverErrors" />
      <portal to="nav-bar-right-column">
        <div class="d-flex align-items-center">
          <span v-if="hasClientErrors" class="mr-1 d-flex align-items-center text-danger">
            <feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes
          </span>
          <b-link size="xs" class="font-weight-normal text-secondary mr-1 font-small-2" @click="$emit('cancel')">
            <FeatherIcon icon="CornerDownLeftIcon" class="mr-25" size="12" />
            Back to list</b-link
          >
          <b-button type="submit" :variant="saveButtonVariant" class="mr-1" @click="onSave" :disabled="!(isDirty && $acl.can('admin.tokens.manage'))">
            Save
          </b-button>
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="confirmDelete = true" v-if="$acl.can('admin.tokens.manage')">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </portal>
      <validation-observer ref="apiTokenForm">
        <fieldset :disabled="!$acl.can('admin.tokens.manage')">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <text-input v-model="apitoken.name" size="lg" placeholder="Name" label="Name" name="Name" validation-rules="required" />
              </b-col>
              <b-col cols="6">
                <b-form-group label="Key" label-for="key" class="mr-1">
                  <b-input-group class="input-group-merge">
                    <b-form-input v-model="apitoken.token" :type="passwordFieldType" class="form-control-merge" :readonly="true" />

                    <b-input-group-append is-text>
                      <div>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                          v-b-tooltip.hover.v-secondary.top
                          title="Show"
                        />
                      </div>
                      <div>
                        <b-link
                          v-b-tooltip.hover.v-secondary.top
                          variant="link"
                          title="Copy to clipboard"
                          v-clipboard:copy="apitoken.key"
                          v-clipboard:success="onCopyKeySuccess"
                        >
                          <feather-icon class="cursor-pointer text-dark ml-1" icon="CopyIcon" />
                        </b-link>
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- names -->
              <b-col cols="12">
                <b-form-row>
                  <b-form-group label="Valid From" label-for="validFrom" class="mr-1">
                    <b-input-group class="input-group-merge">
                      <validation-provider #default="{ errors }" name="Valid From" rules="">
                        <date-picker v-model="apitoken.validFrom" :mode="config.date.mode" :masks="config.date.masks" placeholder="Date">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" /> </template
                        ></date-picker>
                        <br /><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="Valid To" label-for="validTo">
                    <b-input-group class="input-group-merge">
                      <validation-provider #default="{ errors }" name="Valid To" rules="">
                        <date-picker v-model="apitoken.validTo" :mode="config.date.mode" :masks="config.date.masks" placeholder="Date">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" /> </template
                        ></date-picker>
                        <br /><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-col>
              <b-col cols="2"> </b-col>
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="isActive" name="isActive" v-model="apitoken.isActive">
                      Is Active
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- <vue-json-pretty :data="apitoken" /> -->
              <b-col cols="12">
                <PermissionSelector v-model="apitoken.permissions" :permissions="permissions" />
              </b-col>
            </b-row>
          </b-form>
        </fieldset>
      </validation-observer>
    </b-card>
    <b-modal id="deleteConfirmModal" hide-footer title="Api Token" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete Api Token</h4>
        <p class="h6 font-weight-normal">Do you want to delete the Api Token?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { apitokens } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import PermissionSelector from '../../../../../components/Permission/PermissionSelector.vue'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'EditApiToken',
  props: ['value'],
  components: { DatePicker, PermissionSelector },
  mixins: [FormComponent, togglePasswordVisibility],
  data() {
    return {
      permissions: [],
      confirmDelete: false,
      isDirty: false,
      apitoken: { permissions: [] },
      config: {
        date: {
          mode: 'date',
          color: 'blue',
          masks: {
            input: 'YYYY-MM-DD',
          },
        },
      },
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.original = this.value
        this.apitoken = this.$_.cloneDeep(this.original)
      },
    },
    apitoken: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  mounted() {},
  created() {
    this.fetchPermissions()
  },
  methods: {
    async fetchPermissions() {
      const self = this
      await apitokens.getPermissionSet(self.projectName).then(res => {
        self.permissions = res.data
      })
    },
    async onSave() {
      const self = this

      const validation = await self.$refs.apiTokenForm.validateWithInfo()

      if (validation.isValid) {
        apitokens
          .updateApiToken(self.projectName, self.apitoken.id, self.apitoken)
          .then(res => {
            self.hasClientErrors = false
            self.serverErrors = []
            self.$emit('input', { ...self.apitoken })
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.hasClientErrors = true
      }
    },
    async onDelete() {
      const self = this
      apitokens
        .deleteApiToken(self.projectName, self.apitoken.id)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.$emit('delete')
        })
        .catch(err => {
          self.hasClientErrors = true
          self.confirmDelete = false
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
    onCopyKeySuccess() {
      this.$bus.$emit('notification', { ...DefaultNotifications.infoSuccesful, text: 'Api Token copied to clipboard!', position: 'top-right', icon: 'KeyIcon' })
    },
  },
}
</script>
